import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card, User, Avatar } from '@nextui-org/react'
import { XMasonry, XBlock } from "react-xmasonry"
import { Link, useParams } from "react-router-dom"


export default Membership = ->
  <>
    <Grid.Container gap={2}>
      <Grid xs={4}>
        <Link to='/'>
          <Card variant="flat">
            <Card.Body>
              <Text b>
                {"< Back"}
              </Text>
            </Card.Body>
          </Card>
        </Link>
      </Grid>
    </Grid.Container>
    <Container justify='center' align='center' css={p: 20}>
      <Card variant="flat">
        <Card.Body>
          <Text h1 align='center'>Creator Membership</Text>
          <Container css={p: 20}>
            <Text size="$3xl">We sponsor creators for all communities and organizations. Creators are the most valuable asset in the world and should not be locked by any organization or community. Therefore, we see creators as individuals independent of anyone else.</Text>
            <Text size="$3xl">Whether you want to sponsor or be sponsored, please contact us: <Text b>sponsor@joinus.community</Text></Text>
          </Container>
        </Card.Body>
      </Card>
    </Container>
  </>
