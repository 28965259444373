export default orgs = [
  { admin: {name: 'Rory Kennedy', id: "024c4e1508"}, mode: 'audio', stage: 'released', community: 'listenrcloud', title: 'Listenr.Cloud', name: 'listenrcloud', aspectRatio: '9:16', domain: 'listenr.cloud', description: 'Cloud service for podcasts, radio stations, music, natural sounds, background music and sound materials.', bgColor: '#06b6ff', objectFit: 'contain' },
  { admin: {name: 'Deshawn Walter', id: "0683e30c92"}, mode: 'audio', stage: 'released', community: 'listenrcloud', title: 'MMIIXX.IN', name: 'mmiixxin', domain: 'mmiixx.in', aspectRatio: '9:16', description: 'Music festivals, DJ shows, symphony orchestras, pop singers, rock bands performances. As well as interactive programs and talent shows involving bands, orchestras, singers, and musicians.', bgColor: '#1a1a1a', objectFit: 'contain' },
  { admin: {name: 'Roberto Sloan', id: "07775f4646"}, mode: 'audio', stage: 'released', community: 'listenrcloud', title: 'MUB.LA', name: 'mubla', domain: 'mub.la', aspectRatio: '9:16', description: 'Music box for happy dancers. Create, manage, produce your own dance music album. Dance, record and share. Watch and learn from other dancers. Create your own unique style, skills and moves to become a rising star.', objectFit: 'cover' },
  { admin: {name: 'Conor Mason', id: "0ef942f22b"}, mode: 'audio', stage: 'released', community: 'listenrcloud', title: 'NAVAVA', name: 'navava', domain: 'navava.com', aspectRatio: '9:16', description: 'The Darkest Community For Darkest Soul. Techno music and minimal techno music. Gothic and dark music. Battle and war music. Funeral music and Requiem. Psychic and afterlife world. Astrology, divination and occult prophecy. Dark anime, stories and movies. Underground culture, cult culture, evil spirit worship and evil sacrifice. Vampires, zombies, xenos, mutants and geocentric beings.' },
  { admin: {name: 'Jack Hart', id: "12271588db"}, mode: 'audio', stage: 'released', community: 'listenrcloud', title: 'NIGHTU.BE', name: 'nightube', domain: 'nightu.be', aspectRatio: '9:16', description: 'Nightcore Fan Club. Nightcore collection & sharing. Nightcore playlist making & sharing.', bgColor: '#404860' },
  { admin: {name: 'Stanley Lara', id: "1572e8acdc"}, mode: 'audio', stage: 'released', community: 'listenrcloud', title: 'Rap.Rip', name: 'raprip', domain: 'rap.rip', aspectRatio: '9:16', description: 'Can you speak English? Or whatever language you speak. Then you can rap. Take care of your talent and you can be The Next Super Rapper.', bgColor: '#f9004b', objectFit: 'contain' },
  { admin: {name: 'Axton Ramirez', id: "19f2d14962"}, mode: 'audio', stage: 'released', community: 'valala', title: 'HIHIHOHO', name: 'hihihoho', domain: 'hihihoho.com', aspectRatio: '9:16', description: 'Hip Hop Fan Community. Includes music, dance, fashion trends and arts. Share your arts and likes. Discover more talent people just like you.', bgColor: '#0c6859' },
  { admin: {name: 'Cody Porter', id: "22eeaa92e6"}, mode: 'video', stage: 'intend', community: 'valala', title: 'Zero Tube', name: '0tube', domain: '0tu.be', aspectRatio: '5:4', description: 'Zero effort to discover & share the Mysteries of the World.', bgColor: '#f7f7f7' },
  { admin: {name: 'Liam Wilson', id: "2bf1241dbd"}, mode: 'video', stage: 'released', community: 'valala', title: 'ALA.COOL', name: 'alacool', domain: 'ala.cool', aspectRatio: '9:16', description: 'Life ideas, parent-child interaction, cool games and fun stories, selfies, vlogs and more for kids, adults and families.', bgColor: '#f7f7f7' },
  { admin: {name: 'Zak Brooks', id: "3325e7f83f"}, mode: 'video', stage: 'released', community: 'valala', title: 'ALA.TODAY', name: 'alatoday', domain: 'ala.today', aspectRatio: '9:16', description: 'What interesting things are happening in the world right now? Record and share funny moments from your daily life. Spread the latest fashion trends, hot events, celebrities, influencers and the most important person in the world - You.', bgColor: '#f7f7f7' },
  { admin: {name: 'Blaise Gallagher', id: "36d4559d67"}, mode: 'video', stage: 'released', community: 'valala', title: 'ANAVAYA', name: 'anavaya', domain: 'anavaya.com', aspectRatio: '5:4', description: 'Luxury houses, mansions, villas, penthouses report, analysis, comparison, video tour and recommendations. As well as knowledges, brands, product information, the latest technology trends related to private jets, yachts, RVs. And any brand, product, service, technological invention related to luxury lifestyle.', bgColor: '#58add7' },
  { admin: {name: 'Jayden Elliott', id: "3956a87bcb"}, mode: 'video', stage: 'released', community: 'valala', title: 'BareBrain', name: 'barebrain', domain: 'barebra.in', aspectRatio: '5:4', description: 'Welcome sexy lady and gentlemen. Show us your mind, spirit, thoughts, and maybe your body. ', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Isaac Mcknight', id: "3b7d175eda"}, mode: 'video', stage: 'released', community: 'valala', title: 'CABANALA', name: 'cabanala', domain: 'cabana.la', aspectRatio: '5:4', description: "Travel, vacation, travel around the world, travel for food. Walking tour, food tour, hiking, swimming, surfing, diving, sailing, climbing and any outdoor activity.", bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Charles Johnston', id: "3d6e150c59"}, mode: 'video', stage: 'released', community: 'valala', title: 'CoCuPa', name: 'cocupa', domain: 'cocupa.com', aspectRatio: '5:4', description: 'Discover best content on internet. We copy, we cut, we paste, we are proud cocupar.' },
  { admin: {name: 'Kade Russo', id: "4036974c42"}, mode: 'video', stage: 'released', community: 'valala', title: 'DDOOGG.DOG', name: 'ddooggdog', domain: 'ddoogg.dog', aspectRatio: '9:16', description: 'Mainly for dog petting, playing, life recording and sharing. Also includes cats and all other pets. You can also share wild animals as well as any creatures of nature.', bgColor: '#f7f7f7' },
  { admin: {name: 'Harvey Marshall', id: "42706a56a0"}, mode: 'video', stage: 'released', community: 'valala', title: 'FIIXXX', name: 'fiixxx', domain: 'fiixxx.com', aspectRatio: '5:4', description: 'Computer, mobile phone, TV, home appliance, car and house repair, maintenance. Daily life, home, kitchen tools repair and maintenance. And computer, digital software system recovery, repair and maintenance. There are also climate change, wildlife protection, natural and cultural heritage protection, environmental protection and other issues, news.', bgColor: '#f7f7f7' },
  { admin: {name: 'Augustine Turner', id: "4505fb0872"}, mode: 'video', stage: 'released', community: 'valala', title: 'JIMJ.IM', name: 'jimjim', domain: 'jimj.im', aspectRatio: '9:16', description: 'Celebrity news, gossip and events. Film festivals, red carpet shows and awards shows. Movies, music albums and music TV trailers & interviews with producers, actors, singers. Fan made videos, music and shows. Fans catched star moments. Fans digged unknow truth.' },
  { admin: {name: 'James Brooks', id: "47891fe7ce"}, mode: 'video', stage: 'released', community: 'valala', title: 'Ooh.so', name: 'oohso', domain: 'ooh.so', aspectRatio: '9:16', description: 'Funny Video Community. Make and share funny videos. Record and share funny moments in your life. Watch and rate funny videos. Rate how funny a funny video is. Weekly, monthly, annual Top Funny Videos and Awards.', bgColor: '#ffff00', objectFit: 'contain' },
  { admin: {name: 'Izaiah Brewer', id: "4a65f28104"}, mode: 'video', stage: 'released', community: 'valala', title: 'PPLL.PL', name: 'ppllpl', domain: 'ppll.pl', aspectRatio: '9:16', description: 'People Likes People. Give a Like to People you Like.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Taylor Richardson', id: "523ade9a45"}, mode: 'video', stage: 'released', community: 'valala', title: 'RedRibbonRider', name: 'redribbonrider', aspectRatio: '5:4', domain: 'redribbonrider.com', description: 'Motorcycles, road trips, camping, tattoos, piercings. American West landscapes, natives, Indians, traditions, stories, movies, drinks and food.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Bruno Petersen', id: "58f55d3cf0"}, mode: 'video', stage: 'intend', community: 'valala', title: 'VUVUZE.LA', name: 'vuvuzela', domain: 'vuvuze.la', aspectRatio: '9:16', description: 'Sound material, speech, Internet radio, voice message, voice chat.' },
  { admin: {name: 'Luca Reynolds', id: "5d2bf51235"}, mode: 'video', stage: 'released', community: 'valala', title: 'Woo.Cool', name: 'woocool', domain: 'woo.cool', aspectRatio: '5:4', description: 'Cool products, technology, inventions and innovations. Mechanical, Engineering and Construction. You can record and share anything you see, use, and build. Including: tools, products, technologies and more.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Evan Duncan', id: "666955c19a"}, mode: 'video', stage: 'released', community: 'valala', title: 'World Movie Map', name: 'worldmoviemap', aspectRatio: '5:4', domain: 'worldmoviemap.com', description: 'Video clips, trailers. Press conference, filming footage, behind-the-scenes shoots. Interviews with stars,  directors, actors and actresses.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Anthony Sloan', id: "6e3a105dc3"}, mode: 'video', stage: 'released', community: 'valala', title: 'WWOO.MEN', name: 'wwoomen', domain: 'wwoo.men', aspectRatio: '9:16', description: "A women-oriented relationship, family, parent-child relationship community. First date skills, social skills, male psychology knowledge. Wedding preparations, honeymoon travel plans, living options, home buying knowledge. Marriage relationship maintenance skills. Pregnancy, birth planning. Parenting knowledge, skills. Early education, district selection. Children's psychological counseling, behavior training. And more...", bgColor: '#dcdcdc' },
  { admin: {name: 'Tyler Atkinson', id: "750b132859"}, mode: 'video', stage: 'intend', community: 'valala', title: 'YYTT.YT', name: 'yyttyt', domain: 'yytt.yt', aspectRatio: '9:16', description: 'A Handy Tool for Youtubers All Over the World. Generate short videos or short video streams from YouTube videos.', bgColor: '#f7f7f7' },
  { admin: {name: 'Riley Evans', id: "7a9b4e829a"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'BBOOTT', name: 'bboott', domain: 'bboott.com', aspectRatio: '5:4', description: 'News, technologies, communities, and tools related to artificial intelligence, Internet of Things, automatic control, and robotics. ', bgColor: '#f7f7f7' },
  { admin: {name: 'Ezra Gilliam', id: "8014c6d4a9"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'BIZARRELLA', name: 'bizarrella', domain: 'bizarrella.com', aspectRatio: '5:4', description: "Bizarre stories, videos and experiences. Don't be afraid of all beings. God will always be with you. "},
  { admin: {name: 'Theo Roberts', id: "866f029cf7"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'Boo.Cool', name: 'boocool', domain: 'boo.cool', aspectRatio: '9:16', description: 'I`m Boo! Cool? Huh? Cartoon, anime, cosplay, furry, idol and prank videos for teens and ever-teens.', bgColor: '#f7f7f7' },
  { admin: {name: 'Ernest Walters', id: "8b2eb0ef67"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'BorringNews', name: 'borringnews', domain: 'borringnews.com', aspectRatio: '5:4', description: 'If you are bored enough, you MUST read some Borringnews. Subscribe, share and publish the latest news, local news and world news. Daily, hourly and minute updates.' },
  { admin: {name: 'Keegan Melendez', id: "8ffa01c35d"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'Geek.Fail', name: 'geekfail', domain: 'geek.fail', aspectRatio: '5:4', description: 'Geek news, skill, knowledgebase and tools. We build community for tech fans, engineers, developers and trainers. Showcase your work, share your tips and ask for help. We are geeks, we are intelligent and kind people, always helpful, good at solving problems and sharing with the world.', bgColor: '#364b71' },
  { admin: {name: 'Harry Fletcher', id: "997290b90c"}, mode: 'video', stage: 'intend', community: 'ghozzt', title: 'Ghozzt', name: 'ghozzt', domain: 'ghozzt.com', aspectRatio: '5:4', description: 'Look ma! A ghost.'},
  { admin: {name: 'Kian Ellis', id: "9cc39899d0"}, mode: 'video', stage: 'intend', community: 'ghozzt', title: 'ICCUUU.ICU', name: 'iccuuuicu', domain: 'iccuuu.icu', aspectRatio: '5:4', description: "Exclusive content that you won't see anywhere on the internet except here, sourced from Ghozzt Hella Gate's real-time updates.", bgColor: '#f7f7f7' },
  { admin: {name: 'Lucas Thomson', id: "a0b0a6f97e"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'Infomation.Technology', name: 'infomationtechnology', aspectRatio: '5:4', domain: 'infomation.technology', description: 'Infomation.Technology is a practice-driven, community-centric technology media platform dedicated to promoting the dissemination of knowledge and innovation in software development and related fields, providing the latest technology information related to software development, such as architecture, cloud computing, AI, front-end, big data, etc. , Interpretation and Technical Conference.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Bailey Matthews', id: "a5f0f90c0a"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'KKSSSS', name: 'kkssss', domain: 'kkssss.com', aspectRatio: '5:4', description: 'Technical solutions, cloud computing cases, legal cases, diet formulas, game strategies, life encyclopedias, travel guides and other professional knowledge base videos.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Jackson Dawson', id: "a9aa785436"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'Locked.Zone', name: 'lockedzone', domain: 'locked.zone', aspectRatio: '5:4', description: 'Knowledge, skills and Q&A community for non-network security professional users, information encryption, network security, personal computer, mobile phone, digital device security and system maintenance.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Alexzander Sparks', id: "b0aad514be"}, mode: 'video', stage: 'released', community: 'ghozzt', title: 'People Should Know', name: 'peopleshouldknow', aspectRatio: '5:4', domain: 'peopleshouldknow.com', description: 'People Should Know News, Politics, Facts, Stories, History, Religion, Documentaries, Geography, Astronomy, Space, Science, Technology, Engineering, Machinery, Transportation, Health, Business, Economy, Finance...' },
  { admin: {name: 'Freddy Kelly', id: "b69f24bd1a"}, mode: 'video', stage: 'intend', community: 'ghozzt', title: 'VVCC.VC', name: 'vvccvc', domain: 'vvcc.vc', aspectRatio: '9:16', description: 'Video Clips & Video Cutter' },
  { admin: {name: 'Ronin Norton', id: "bb8aafbc2a"}, mode: 'video', stage: 'released', community: 'mipier', title: 'Burritozil.la', name: 'burritozilla', domain: 'burritozil.la', aspectRatio: '5:4', description: 'Burritos, Mexican food, Latino food, Filipino food and any street food in America. ', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Zain Terrell', id: "c3097ea76f"}, mode: 'video', stage: 'released', community: 'mipier', title: 'ChainChoo', name: 'chainchoo', domain: 'chainchoo.com', aspectRatio: '5:4', description: "Korean food and recipe, seoul street food, korean restaurant in LA, korean restaurant around US and World. Korean culture, family and people's story about korean food.", bgColor: '#f7f7f7' },
  { admin: {name: 'Kendrick Wells', id: "ca1f5aa7db"}, mode: 'video', stage: 'intend', community: 'mipier', title: 'COCALA.CO', name: 'cocalaco', domain: 'cocala.co', aspectRatio: '5:4', description: 'Mexican Music, Dance, Art, Culture, Food, Nature, Wildlife, Alcohol, Drinks and People.'},
  { admin: {name: 'Sean Houghton', id: "d1e51b7a78"}, mode: 'video', stage: 'released', community: 'mipier', title: 'GGIINN.IN', name: 'ggiinnin', domain: 'ggiinn.in', aspectRatio: '9:16', description: 'Gin and other spirits tasting, history, brands, culture, stories and sharing by spirit fans.', bgColor: '#f7f7f7' },
  { admin: {name: 'Oliver Francis', id: "d59414bc1b"}, mode: 'video', stage: 'released', community: 'mipier', title: 'dapa.la', name: 'dapala', domain: 'dapa.la', aspectRatio: '9:16', description: 'Cocktail, mocktail recipes, bars and culture. Bartending tools, knowledge, tips and tutorials.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Declan White', id: "de7664621c"}, mode: 'video', stage: 'released', community: 'mipier', title: 'DripD.rip', name: 'dripdrip', domain: 'dripd.rip', aspectRatio: '9:16', description: 'Alcohol and alcoholic beverages. For example: wine, beer, whiskey, vodka, gin, brandy, tequila, rum, etc.', bgColor: '#f7f7f7', objectFit: 'contain' },
  { admin: {name: 'Toby Marshall', id: "e5c7842704"}, mode: 'video', stage: 'released', community: 'mipier', title: 'Jagerdumpling', name: 'jagerdumpling', domain: 'jagerdumpling.com', aspectRatio: '9:16', description: 'As a worldwide cuisine. Dumplings have different versions in different countries. As long as we learn about different versions of dumplings, we learn about the cultures, traditions and peoples. In the name of dumpling, we build this community for dumpling lovers all countries all over the world. World Peace & Love Dumpling.', bgColor: '#f7f7f7', objectFit: 'contain' },
  {  admin: {name: 'Eddie Levy', id: "ed65e2d08b"}, mode: 'video', mode: 'video', stage: 'released', community: 'mipier', title: 'Street Foo', name: 'streetfoo', domain: 'streetfoo.com', aspectRatio: '5:4', description: 'Everything about street food.', bgColor: '#f7f7f7', objectFit: 'contain' },
  {  admin: {name: 'Leland Hebert', id: "f39e17ca49"}, mode: 'video', mode: 'video', stage: 'intend', community: 'mipier', title: 'Santo.la', name: 'santola', domain: 'santo.la', aspectRatio: '9:16', description: 'American and Mexican Fast Food Fan Community. For example: fries, fried chicken, burgers, sandwiches, pizza, hot dogs and burritos. Try it on the spot, copy it at home.', bgColor: '#cd242b', objectFit: 'contain' },
  {  admin: {name: 'Alfred Burgess', id: "f9bc22a117"}, mode: 'video', mode: 'video', stage: 'released', community: 'mipier', title: 'Manzanil.la', name: 'manzanilla', domain: 'manzanil.la', aspectRatio: '9:16', description: 'Cheese, salad, steak, bread, dessert, French food, Italian food, British food and any other European traditional food. And related drinks, beverages and culture. ', bgColor: '#f7f7f7' },
  {  admin: {name: 'Aidan Lawson', id: "fc0ed98c22"}, mode: 'video', mode: 'video', stage: 'released', community: 'mipier', title: 'Mipier', name: 'mipier', domain: 'mipier.com', aspectRatio: '9:16', description: 'Recipe collections of all kinds of food. Professional Chef Tutorial. Home cooking to share. Chinese restaurant, Japanese restaurant, steakhouse, Michelin star restaurant recommendation. Hotel food, buffet experience.', bgColor: '#f7f7f7', objectFit: 'contain' },
  {  admin: {name: 'Parker Yates', id: "67d1648593"}, mode: 'video', mode: 'video', stage: 'released', community: 'mipier', title: 'NeighborFoo', name: 'neighborfoo', domain: 'neighborfoo.com', aspectRatio: '5:4', description: 'Food, Restaurants, Fast Food Chains, and Takeaways in Neighborhood. Record and share your food experience in neighborhood. Your experience are highly helpful for others.', bgColor: '#f7f7f7', objectFit: 'contain' },
  {  admin: {name: 'Hugo Mason', id: "7214ff61d4"}, mode: 'video', mode: 'video', stage: 'intend', community: 'mipier', title: 'VALA.BAR', name: 'valabar', domain: 'vala.bar', aspectRatio: '9:16', description: 'Online Virtual Bar. Spirits, cocktail, wine, beverages presentations. Cheese, salad, dessert recipes and presentations. Games, shows and interactive performances.', bgColor: '#f7f7f7', objectFit: 'contain' },
  {  admin: {name: 'Samuel Lane', id: "559a4e4460"}, mode: 'video', mode: 'video', stage: 'released', community: 'iinnin', title: 'AASSSS', name: 'aassss', domain: 'aassss.com', aspectRatio: '9:16', description: 'The eden for beautiful and sexy lady, upload your twerk videos, bikini photos, and spreads your peach butt to the world.', bgColor: '#d9dfe2' },
  {  admin: {name: 'Michael Read', id: "5f593598b3"}, mode: 'video', mode: 'video', stage: 'released', community: 'iinnin', title: 'BICKINI', name: 'bickini', domain: 'bickini.com', aspectRatio: '9:16', description: 'Yet another Bickini you should try. Share your bikini show, beach journal, favorites and ideas. ', bgColor: '#f7f7f7' },
  {  admin: {name: 'Maurice Melton', id: "6532c9237c"}, mode: 'video', mode: 'video', stage: 'released', community: 'iinnin', title: 'Cuyana.la', name: 'cuyanala', domain: 'cuyana.la', aspectRatio: '5:4', description: 'Girls only. Body goals, fashion goals, fitness goals, life goals, and more. Make a wish and it will come true sooner or later.', bgColor: '#f7f7f7' },
  {  admin: {name: 'Francisco Serrano', id: "9622ac7981"}, mode: 'video', mode: 'video', stage: 'released', community: 'iinnin', title: 'IINN.IN', name: 'iinnin', domain: 'iinn.in', aspectRatio: '9:16', description: 'Fashion Shows, Trends, New Clothes of the Season and Street Fashion. Bring your phone, Get your camera ready, Fashion is Everywhere, Fashion is In You.', bgColor: '#fb8f66' },
  {  admin: {name: 'Ashton Clark', id: "a11108ad2b"}, mode: 'video', mode: 'video', stage: 'released', community: 'iinnin', title: 'VER.VET', name: 'vervet', domain: 'ver.vet', aspectRatio: '9:16', description: 'Teen Fashion Trends. Hoodies, t-shirts, jackets, jeans, adidas, nike, new balance, airwalk, converse. Hats, headwear, scarves, belts, socks, shoelaces selection and tips.' },
  {  admin: {name: 'Lewis Webb', id: "ad2a679ee9"}, mode: 'video', mode: 'video', stage: 'released', community: 'cupidglobal', title: 'COLOR.SO', name: 'colorso', domain: 'color.so', aspectRatio: '9:16', description: 'Adult only life sharing, couple show, private show, hang out and dating video app.', bgColor: '#c383b8' },
  {  admin: {name: 'Eden Blackburn', id: "b6aca0734a"}, mode: 'video', mode: 'video', stage: 'intend', community: 'cupidglobal', title: 'FAAXXX', name: 'faaxxx', domain: 'faaxxx.com', aspectRatio: '9:16', description: 'Fat Ass Porn Video Network for ass fan. Includes latino fat ass, black fat ass, white fat ass and also includes any other porn videos, photos and stuffs.', bgColor: '#f7f7f7', objectFit: 'contain' },
  {  admin: {name: 'Rayan Andrews', id: "be47f73a92"}, mode: 'video', mode: 'video', stage: 'released', community: 'cupidglobal', title: 'FooFxx', name: 'foofxx', domain: 'foofxx.com', aspectRatio: '5:4', description: 'Sexy kitchens, sexy foodies, bikini food tours, nudist sex parties, nudist sex dinners, nudist beach orgies and tons of hardcore porn stuffs to satisfy your hunger and thirst.' },
  {  admin: {name: 'Ben Delacruz', id: "c8486fdaf9"}, mode: 'video', mode: 'video', stage: 'intend', community: 'cupidglobal', title: 'TAAXXX', name: 'taaxxx', domain: 'taaxxx.com', aspectRatio: '9:16', description: "Today's Fat Ass Porn Video Update. With the latest videos updated in real time, You Will NO LONGER REGRET MISSING A FAT ASS.", bgColor: '#f7f7f7', objectFit: 'contain' },
  {  admin: {name: 'Joel Mills', id: "eb26dfd8e3"}, mode: 'video', mode: 'video', stage: 'released', community: 'cupidglobal', title: 'FOOXXX', name: 'fooxxx', domain: 'fooxxx.com', aspectRatio: '9:16', description: 'Stay Foolish, Stay Sexy. Adult dating, camping, hiking and backpacking sharing. And many more life records, funny moments and food vlog sharing.' },
  {  admin: {name: 'Gianni Hartman', id: "f43c941b5d"}, mode: 'video', mode: 'video', stage: 'released', community: 'letsdancelive', title: "Let's Dance Live!", name: 'letsdancelive', domain: 'letsdancelive.com', aspectRatio: '9:16', description: 'Your Online Dance Hall.', bgColor: '#f7f7f7', objectFit: 'contain' },
  {  admin: {name: 'Louis Barnes', id: "023b68b160"}, mode: 'video', mode: 'video', stage: 'released', community: 'letsdancelive', title: 'BounceKidzz', name: 'bouncekidzz', domain: 'bouncekidzz.com', aspectRatio: '9:16', description: 'Shuffle dance, club dance, party & festival videos. Shoot and share your current parties, carnivals, music festivals, dance parties and any dance related events. Or just dance and share.', bgColor: '#f7f7f7' },
  {  admin: {name: 'Scott Spencer', id: "0cac16a4a0"}, mode: 'video', mode: 'video', stage: 'released', community: 'letsdancelive', title: 'JumpJumpBangBang', name: 'jumpjumpbangbang', domain: 'jumpjumpbangbang.com', aspectRatio: '9:16', description: 'Aerobics, fitness dance, ballroom dance, Latin dance, belly dance, jump rope, bounce mat and all rhythmic fitness activities. Record your jump-jump-bang-bang and share your happy moments.' },
  {  admin: {name: 'Joel Chapman', id: "16fec482fb"}, mode: 'video', mode: 'video', stage: 'released', community: 'zzoooo', title: 'ZZOOOO', name: 'zzoooo', domain: "zzoooo.com", aspectRatio: '9:16', description: 'Online community for youth.', objectFit: 'contain'},
  {  admin: {name: 'Howard Greer', id: "24bfba79fc"}, mode: 'video', mode: 'video', stage: 'intend', community: 'vines', title: 'Vines Again', name: 'vinescommunity', domain: 'vines.community', aspectRatio: '9:16', description: 'Legendary Vines Community Reborn & Rebuild. Many Thanks to Vines.Community for Making Vines Fun Again, Cool Again and Great Again. So the first released app is named Vines Again.' },
  {  admin: {name: 'Scott Spencer', id: "359c6779e0"}, mode: 'video', mode: 'video', stage: 'intend', community: null, title: 'Hao.Re 好人网', name: 'haore', domain: 'hao.re', aspectRatio: '5:4', description: '好人网 - 报道好人好事，弘扬正气，传播正能量。', bgColor: '#f7f7f7', objectFit: 'contain'},
  {  admin: {name: 'Billy Rees', id: "4e3c9a2537"}, mode: 'video', mode: 'video', stage: 'intend', community: null, title: 'GGYY.GY 叽叽歪歪.叽歪', name: 'ggyygy', domain: 'ggyy.gy', aspectRatio: '5:4', description: '全球华人叽叽歪歪网 - 你懂的 ;)', bgColor: '#f7f7f7', objectFit: 'contain'},
  {  admin: {name: 'Krish Burke', id: "5fce5325dc"}, mode: 'video', mode: 'video', stage: 'intend', community: null, title: 'BDSG.LIVE 扁豆丝瓜直播', name: 'bdsglive', domain: "bdsg.live", aspectRatio: '5:4', description: '扁豆丝瓜网 【最新报道】一个Diao丝躺平后被踏上了一万只脚，生生被踩成了扁丝，惨不忍睹啊！（直播中）友情提示：生活有风险，躺平需谨慎！', bgColor: '#f7f7f7', objectFit: 'contain'},
  {  admin: {name: 'Colton Porter', id: "6bbe8371dc"}, mode: 'video', mode: 'video', stage: 'released', community: null, title: 'g63.gg', name: 'g63gg', domain: 'g63.gg', aspectRatio: '5:4', description: "We do not reject and treat equally any videos, content and people, which may be considered ordinary, boring, unreal and unattractive on other websites, apps and platforms. If you and your works are not noticed in other places, please share with us, we value any of your works and arts. It will be recommended to users with equal opportunity and saved forever. So that you or your fans can rediscover the value at any time in the future.(BTW g63.gg is the shortcut for world's longest domain name: gggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg.gg)", bgColor: '#f7f7f7' },
]

export orgNameList = orgs.filter(({stage})->stage is 'released').map ({name}) -> name

export orgMap = {}

for org in orgs
  orgMap[org.name] = org
