import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card } from '@nextui-org/react'
import { XMasonry, XBlock } from "react-xmasonry"
import { Link, useParams } from "react-router-dom"

import {CommunityCard, OrgCard} from '../components/Card'
import {HeartIcon} from '../components/Icon'

import communities from '../data/communities'
import orgs from '../data/orgs'

export default Community = ->
  {community} = useParams()
  {name, domain, title, description, logo} = communities[community]
  cclist = Object.values(communities).filter (item) -> item.name isnt 'valala'
  <>
    <Grid.Container gap={2}>
      <Grid xs={4}>
        <Link to='/'>
          <Card variant="flat">
            <Card.Body>
              <Text b>
                {"< Back"}
              </Text>
            </Card.Body>
          </Card>
        </Link>
      </Grid>
    </Grid.Container>
    <Grid.Container justify="center" align='center'>
      <Grid>
        <Spacer y={2} />
        <Container css={padding: 20}>
          <Text h6 size={50}>{domain}</Text>
          <Image width='412px' height='412px' src={logo} />
        </Container>
        <Text h1 size={60} weight="bold">
        {title}
        </Text>
      </Grid>
    </Grid.Container>
    <Spacer y={1} />
    <Container align='center'>
      <Button
        as={Link}
        to="/membership/community/#{community}"
        style={width: 500}
        auto
        flat
        bordered
        borderWeight="extrabold"
        size='xl'
        color="error"
        icon={<HeartIcon fill="currentColor" filled />}
      >
        {title} Love You
      </Button>
    </Container>
    <Spacer y={3} />
    <Container align='center'>
      <Grid.Container justify="center" align='center' gap={2}>
        <Grid xs={4}>
          <Card>
            <Card.Body style={textAlign: 'center'}>
              <Text b>{description}</Text>
            </Card.Body>
          </Card>
        </Grid>
      </Grid.Container>
    </Container>
    <Spacer y={5} />
    <Container align='center'>
    {
      if community is 'valala'
        <XMasonry targetBlockWidth={500}>
        {
          cclist.map (item) ->
            <XBlock key="cc-#{item.name}">
              <div style={height: '200px'}>
                <CommunityCard {...item} />
              </div>
            </XBlock>

        }
        </XMasonry>
    }
      <Spacer y={3} />
      <XMasonry targetBlockWidth={500}>
      {
        orgs.filter((org)->org.community is community).map (item) ->
          <XBlock key="oc-#{item.name}">
            <div style={height: '900px'}>
              <OrgCard {...item} />
            </div>
          </XBlock>

      }
      </XMasonry>
      <Spacer y={3} />
      <Container align='center'>
      </Container>
    </Container>
  </>
