import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card } from '@nextui-org/react'
import { XMasonry, XBlock } from "react-xmasonry"
import { Link } from "react-router-dom"

import {CommunityCard, OrgCard} from '../components/Card'
import {HeartIcon} from '../components/Icon'

import communities from '../data/communities'
import orgs from '../data/orgs'

export default Home = ->
  <>
    <Grid.Container justify="center" align='center'>
      <Grid>
        <Spacer y={2} />
        <Container css={padding: 20}>
          <Image width='412px' height='412px' src='https://image.bbeeee.cloud/joinus.community/joinuscommunity.png' />
        </Container>
        <Text h1 size={60} weight="bold">
          JOINUS.COMMUNITY
        </Text>
      </Grid>
    </Grid.Container>
    <Container align='center'>
      <Button
        as={Link}
        to='/membership'
        style={width: 550}
        auto
        flat
        bordered
        borderWeight="extrabold"
        size='xl'
        color="error"
        icon={<HeartIcon fill="currentColor" filled />}
      >
        JoinUs.Community Love You
      </Button>
    </Container>
    <Spacer y={3} />
    <Container align='center'>
      <XMasonry targetBlockWidth={500}>
      {
        Object.values(communities).map (item) ->
          <XBlock key="cc-#{item.name}">
            <div style={height: '200px'}>
              <CommunityCard {...item} />
            </div>
          </XBlock>

      }
      </XMasonry>
      <Spacer y={3} />
      <XMasonry targetBlockWidth={500}>
      {
        orgs.map (item) ->
          <XBlock key="oc-#{item.name}">
            <div style={height: '900px'}>
              <OrgCard {...item} />
            </div>
          </XBlock>

      }
      </XMasonry>
      <Spacer y={3} />
      <Container align='center'>
      </Container>
    </Container>
  </>
