import React from 'react'
import { Link, useParams } from "react-router-dom"

import {orgMap} from '../data/orgs'


import PrivacyPolicy from '../components/PrivacyPolicy'


export default Privacy = ->
  {org} = useParams()
  {domain, name, title} = orgMap[org]

  <PrivacyPolicy title={title} domain={domain} />
