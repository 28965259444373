export default communities = {
  valala:
    name: 'valala'
    domain: 'valala.community'
    title: 'Valala Community'
    description: 'Valala.Community is the single original root community for all communities and organizations. If an organization does not belong to any community, it will belong to Valala.Community.'
    logo: 'https://image.bbeeee.cloud/valala.com/valala.png'
  zzoooo:
    name: 'zzoooo'
    domain: 'zzoooo.com'
    title: 'ZZOOOO Community'
    description: 'The community for youth.'
    logo: 'https://image.bbeeee.cloud/zzoooo.com/logo.png'
  ghozzt:
    name: 'ghozzt'
    domain: 'ghozzt.com'
    title: 'Ghozzt Community'
    description: 'Content Creation Community'
    logo: 'https://image.bbeeee.cloud/ghozzt.com/ghozzt-icon.png'
  letsdancelive:
    name: 'letsdancelive'
    domain: 'letsdancelive.com'
    title: 'Lets Dance Live! Community'
    description: 'Dance Lover Community'
    logo: 'https://image.bbeeee.cloud/letsdancelive.com/logo.png'
  mipier:
    name: 'mipier'
    domain: 'mipier.com'
    title: 'Mipier Community'
    description: 'Food Lover Community'
    logo: 'https://image.bbeeee.cloud/mipier.com/mipier-icon.png'
  iinnin:
    name: 'iinnin'
    domain: 'iinn.in'
    title: 'IINN.IN Fashion Community'
    description: 'Fashion Trends & Sharing Community'
    logo: 'https://image.bbeeee.cloud/iinn.in/iinnin-icon.png'
  cupidglobal:
    name: 'cupidglobal'
    domain: 'cupid.global'
    title: 'Cupid Global Community'
    description: 'Adult Sharing Community'
    logo: 'https://image.bbeeee.cloud/cupid.global/logo.png'
  listenrcloud:
    name: 'listenrcloud'
    domain: 'listenr.cloud'
    title: 'Listenr Cloud Community'
    description: 'Music Sharing Community'
    logo: 'https://image.bbeeee.cloud/listenr.cloud/listenrcloud-icon.png'
  vines:
    name: 'vines'
    domain: 'vines.community'
    title: 'Vines Again'
    description: 'Viral Videos Community'
    logo: 'https://image.bbeeee.cloud/vines.community/logo.png'
}
