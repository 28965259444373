import { Card, Container } from "@nextui-org/react"

export default PrivacyPolicy = ({title, domain}) ->
  <Container css={p: 50}>
    <Card>
      <Card.Body css={p: 20}>
    		<h1>Privacy Policy</h1>
      	<section>
      		<div>
      			<p>
      				Welcome to {title} ({domain}). The Platform is powered by BBEEEE.CLOUD and sponsored by JOINUS.COMMUNITY. We are committed to protecting and respecting your privacy. This Privacy Policy covers the experience we provide for
      				users age 13 and over on our Platform.
      			</p>
      		</div>
      	</section>
      	<div>
      		<ul>
      			<li>What information do we collect?</li>
      			<li>How we use your information</li>
      			<li>How we share your information</li>
      			<li>Your Rights</li>
      			<li>Your Choices</li>
      			<li>Security and Storage</li>
      			<li>Other Rights</li>
      		</ul>
      	</div>
      	<section>
      		<div>
      			<h2><strong>What information do we collect?</strong></h2>
      			<p>
      				We collect information when you create an account or use the Platform. We also collect information you share with us from third-party social network providers, and technical and behavioral information about your use of the
      				Platform. We also collect information contained in the messages you send through our Platform and, if you grant us access, information from your phone book on your mobile device. More information about the categories and
      				sources of information is provided below.
      			</p>
      			<h3><strong>Information you choose to provide</strong></h3>
      			<p>For certain activities, such as when you register, upload content to the Platform, or contact us directly, you may provide some or all of the following information:</p>
      			<ul>
      				<li>Registration information, such as age, username and password, language, and email or phone number</li>
      				<li>Profile information, such as name, social media account information, and profile image</li>
      				<li>
      					User-generated content, including comments, photographs, livestreams, audio recordings, videos, and virtual item videos that you choose to create with or upload to the Platform (&ldquo;User Content&rdquo;). We collect
      					User Content through pre-loading at the time of creation, import, or upload, regardless of whether you choose to save or upload that User Content, in order to recommend audio options and provide other personalized
      					recommendations. If you apply an effect to your User Content, we may collect a version of your User Content that does not include the effect.
      				</li>
      				<li>
      					Content, including text, images, and video, found in your device&rsquo;s clipboard, with your permission. For example, if you choose to initiate content sharing with a third-party platform, or choose to paste content
      					from the clipboard into the {title} App, we access this information stored in your clipboard in order to fulfill your request.
      				</li>
      				<li>Payment information, including payment card numbers or other third-party payment information (such as PayPal) where required for the purpose of payment</li>
      				<li>
      					Your phone and social network contacts, with your permission. If you choose to find other users through your phone contacts, we will access and collect the names and phone numbers and match that information against
      					existing users of the Platform. If you choose to find other users through your social network contacts, we will collect your public profile information as well as names and profiles of your social network contacts
      				</li>
      				<li>Your opt-in choices and communication preferences</li>
      				<li>Information to verify an account such as proof of identity or age&nbsp;</li>
      				<li>Information in correspondence you send to us</li>
      				<li>Information you share through surveys or your participation in challenges, sweepstakes, or contests such as your gender, age, likeness, and preferences.</li>
      			</ul>
      			<h3><strong>Information we obtain from other sources</strong></h3>
      			<p>We may receive the information described in this Privacy Policy from other sources, such as:</p>
      			<p>
      				<strong>Social Media and Login Services</strong>. If you choose to link or sign up using a third-party social network or login service (such as Facebook, Twitter, Instagram, or Google), we may collect information from these
      				services, including your contact lists for these services and information relating to your use of the Platform in relation to these services. If you link your {title} account to another service, we may receive information
      				about your use of that service.
      			</p>
      			<p><strong>Third-Party Services</strong>. We may collect information about you from third-party services, such as advertising partners, data providers, and analytics providers.</p>
      			<p><strong>Other Users of the Platform</strong>. Sometimes other users of the Platform may provide us information about you, including through customer service inquiries.</p>
      			<p><strong>Other Sources</strong>. We may collect information about you from other publicly available sources.</p>
      			<h3><strong>Information we collect automatically</strong></h3>
      			<p>
      				We automatically collect certain information from you when you use the Platform, including internet or other network activity information such as your IP address, geolocation-related data (as described below), unique device
      				identifiers, browsing and search history (including content you have viewed in the Platform), and Cookies (as defined below).
      			</p>
      			<p><strong>Usage Information</strong></p>
      			<p>We collect information regarding your use of the Platform and any other User Content that you generate through or upload to our Platform.&nbsp;</p>
      			<p><strong>Device Information</strong></p>
      			<p>
      				We collect certain information about the device you use to access the Platform, such as your IP address, user agent, mobile carrier, time zone settings, identifiers for advertising purposes, model of your device, the device
      				system, network type, device IDs, your screen resolution and operating system, app and file names and types, keystroke patterns or rhythms, battery state, audio settings and connected audio devices. Where you log-in from
      				multiple devices, we will be able to use your profile information to identify your activity across devices. We may also associate you with information collected from devices other than those you use to log-in to the
      				Platform.&nbsp;
      			</p>
      			<p><strong>Location data</strong></p>
      			<p>We collect information about your approximate location, including location information based on your SIM card and/or IP address. With your permission, we may also collect precise location data (such as GPS).</p>
      			<p><strong>Image and Audio Information</strong></p>
      			<p>
      				We may collect information about the images and audio that are a part of your User Content, such as identifying the objects and scenery that appear, the existence and location within an image of face and body features and
      				attributes, the nature of the audio, and the text of the words spoken in your User Content. We may collect this information to enable special video effects, for content moderation, for demographic classification, for content
      				and ad recommendations, and for other non-personally-identifying operations. We may collect biometric identifiers and biometric information as defined under US laws, such as faceprints and voiceprints, from your User
      				Content. Where required by law, we will seek any required permissions from you prior to any such collection.
      			</p>
      			<p><strong>Messages</strong></p>
      			<p>
      				We collect and process, which includes scanning and analyzing, information you provide when you compose, send, or receive messages through the Platform&rsquo;s messaging functionality. That information includes the content
      				of the message and information about when the message has been sent, received and/or read, as well as the participants of the communication. Please be aware that messages sent to other users of the Platform will be
      				accessible by those users and that we are not responsible for the manner in which those users use or disclose messages.
      			</p>
      			<p><strong>Metadata</strong></p>
      			<p>
      				When you upload or create User Content, you automatically upload certain metadata that is connected to the User Content. Metadata describes other data and provides information about your User Content that will not always be
      				evident to the viewer. In connection with your User Content the metadata can describe how, when, where, and by whom the piece of User Content was created, collected, or modified and how that content is formatted. It also
      				includes information, such as your account name, that enables other users to trace back the User Content to your user account. Additionally, metadata includes data that you choose to provide with your User Content, e.g. any
      				hashtags used to mark keywords to the video and captions.
      			</p>
      			<p><strong>Cookies</strong></p>
      			<p>
      				We and our service providers and business partners use cookies and other similar technologies (e.g. web beacons, flash cookies, etc.) (&ldquo;Cookies&rdquo;) to automatically collect information, measure and analyze which
      				web pages and advertisements you click on and how you use the Platform, enhance your experience using the Platform, improve the Platform, provide you with advertising on the Platform and elsewhere across your devices, and
      				measure the effectiveness of advertisements. Cookies enable the Platform to provide certain features and functionality. Web beacons are very small images or small pieces of data embedded in images, also known as &ldquo;pixel
      				tags&rdquo; or &ldquo;clear GIFs,&rdquo; that can recognize Cookies, the time and date a page is viewed, a description of the page where the pixel tag is placed, and similar information from your computer or device. To learn
      				how to disable Cookies, see the &ldquo;Your choices&rdquo; section below.
      			</p>
      			<p>
      				We and our service providers and business partners may link your contact or account information with your activity on and off our Platform across all your devices, using your email or other log-in or device information. Our
      				service providers and business partners may use this information to display advertisements on our Platform and elsewhere online and across your devices tailored to your interests, preferences, and characteristics. We are not
      				responsible for the privacy practices of these service providers and business partners, and the information practices of these service providers and business partners are not covered by this Privacy Policy.
      			</p>
      			<p>We may aggregate or de-identify the information described above. Aggregated or de-identified data is not subject to this Privacy Policy.</p>
      			<h2><strong>How we use your information</strong></h2>
      			<p>
      				As explained below, we use your information to improve, support and administer the Platform, to allow you to use its functionalities, and to fulfill and enforce our Terms of Service. We may also use your information to,
      				among other things, show you suggestions, promote the Platform, and customize your ad experience.
      			</p>
      			<p>We generally use the information we collect:</p>
      			<ul>
      				<li>
      					To fulfill requests for products, services, Platform functionality, support and information for internal operations, including troubleshooting, data analysis, testing, research, statistical, and survey purposes and to
      					solicit your feedback
      				</li>
      				<li>
      					To customize the content you see when you use the Platform. For example, we may provide you with services based on the country settings you have chosen or show you content that is similar to content that you like or
      					interacted with
      				</li>
      				<li>To send promotional materials from us or on behalf of our affiliates and trusted third parties</li>
      				<li>To improve and develop our Platform and conduct product development</li>
      				<li>To measure and understand the effectiveness of the advertising we serve to you and others and to deliver advertising</li>
      				<li>To make suggestions and provide a customized ad experience</li>
      				<li>
      					To support the social functions of the Platform, including to permit you and other users to connect with each other through the Platform and for you and other users to share, download, and otherwise interact with User
      					Content posted through the Platform
      				</li>
      				<li>To use User Content as part of our advertising and marketing campaigns to promote the Platform</li>
      				<li>To understand how you use the Platform, including across your devices</li>
      				<li>To infer additional information about you, such as your age, gender, and interests</li>
      				<li>To help us detect abuse, fraud, and illegal activity on the Platform</li>
      				<li>
      					To prove your identity in order to use certain features, such as livestream or verified accounts, or when you apply for a Pro Account, ensure that you are old enough to use the Platform (as required by law), or in other
      					instances where verification may be required
      				</li>
      				<li>To communicate with you, including to notify you about changes in our services</li>
      				<li>To announce you as a winner of our contest, sweepstakes, or promotions if permitted by the promotion rule, and to send you any applicable prizes</li>
      				<li>To enforce our terms, conditions, and policies</li>
      				<li>Consistent with your permissions, to provide you with location-based services, such as advertising and other personalized content</li>
      				<li>To inform our algorithms</li>
      				<li>To combine all the information we collect or receive about you for any of the foregoing purposes</li>
      				<li>For any other purposes disclosed to you at the time we collect your information or pursuant to your consent.</li>
      				<li>To facilitate sales, promotion, and purchases of goods and services and to provide user support</li>
      			</ul>
      			<h2><strong>How we share your information</strong></h2>
      			<p>
      				We are committed to maintaining your trust, and while {title} does not sell personal information to third parties, we want you to understand when and with whom we may share the information we collect for business purposes.
      			</p>
      			<h3><strong>Service Providers and Business Partners</strong></h3>
      			<p>
      				We share the categories of personal information listed above with service providers and business partners to help us perform business operations and for business purposes, including research, payment processing and
      				transaction fulfillment, database maintenance, administering contests and special offers, technology services, deliveries, sending communications, advertising, analytics, measurement, data storage and hosting, disaster
      				recovery, search engine optimization, marketing, and data processing. These service providers and business partners may include:
      			</p>
      			<ul>
      				<li>
      					Payment processors and transaction fulfillment providers, who may receive the information you choose to provide, the information we obtain from other sources, and the information we collect automatically but who do not
      					receive your message data.
      				</li>
      				<li>Customer and technical support providers, who may receive the information you choose to provide, the information we obtain from other sources, and the information we collect automatically.</li>
      				<li>
      					Research providers, who may receive the information you choose to provide, the information we obtain from other sources, and the information we collect automatically but would not receive your payment information or
      					message data.
      				</li>
      				<li>Cloud providers, who may receive the information you choose to provide, the information we obtain from other sources, and the information we collect automatically.</li>
      				<li>
      					Advertising, marketing, and analytics vendors, who may receive the information you choose to provide, the information we obtain from other sources, and the information we collect automatically but would not receive your
      					payment information or message data.
      				</li>
      			</ul>
      			<h3><strong>Within Our Corporate Group</strong></h3>
      			<p>We may share all of the information we collect with a parent, subsidiary, or other affiliate of our corporate group.</p>
      			<h3><strong>In Connection with a Sale, Merger, or Other Business Transfer</strong></h3>
      			<p>We may share all of the information we collect in connection with a substantial corporate transaction, such as the sale of a website, a merger, consolidation, asset sales, or in the unlikely event of bankruptcy.</p>
      			<h3><strong>For Legal Reasons</strong></h3>
      			<p>
      				We may disclose any of the information we collect to respond to subpoenas, court orders, legal process, law enforcement requests, legal claims, or government inquiries, and to protect and defend the rights, interests,
      				safety, and security of {title}, the Platform, our affiliates, users, or the public. We may also share any of the information we collect to enforce any terms applicable to the Platform, to exercise or defend any legal
      				claims, and comply with any applicable law.
      			</p>
      			<h3><strong>With Your Consent</strong></h3>
      			<p>We may share your information for other purposes pursuant to your consent or at your direction.</p>
      			<p>
      				If you access third-party services, such as Facebook, Google, or Twitter, to login to the Platform or to share information about your usage on the Platform with others, these third-party services may be able to collect
      				information about you, including information about your activity on the Platform, and they may notify your connections on the third-party services about your use of the Platform, in accordance with their privacy policies. If
      				you choose to allow a third-party service to access your account, we will share certain information about you with the third party. Depending on the permissions you grant, the third party may be able to obtain your account
      				information and other information you choose to provide.
      			</p>
      			<p>
      				If you choose to engage in public activities on the Platform, you should be aware that any information you share may be read, collected, or used by other users. You should use caution in disclosing personal information while
      				using the Platform. We are not responsible for the information you choose to submit.
      			</p>
      			<h2><strong>Your Rights</strong></h2>
      			<p>
      				You may submit a request to access or delete the information we have collected about you by sending your request to us at the email or physical address provided in the Contact section at the bottom of this policy. You may be
      				entitled, in accordance with applicable law, to submit a request through an authorized agent. To designate an authorized agent to exercise choices on your behalf, please provide evidence that you have given such agent power
      				of attorney or that the agent otherwise has valid written authority to submit requests to exercise rights on your behalf. We will respond to your request consistent with applicable law and subject to proper verification. We
      				will verify your request by asking you to send it from the email address associated with your account or to provide information necessary to verify your account. We do not discriminate based on the exercise of any privacy
      				rights that you might have.
      			</p>
      			<h2><strong>Your Choices</strong></h2>
      			<ul>
      				<li>
      					You may be able to control some of the information we collect by adjusting your browser settings to refuse or disable Cookies. Because each browser is different, please consult the instructions provided by your browser.
      					Please note that you may need to take additional steps to refuse or disable certain types of Cookies. In addition, your choice to disable cookies is specific to the particular browser or device that you are using when
      					you disable cookies, so you may need to separately disable cookies for each type of browser or device. If you choose to refuse, disable, or delete Cookies, some of the functionality of the Platform may no longer be
      					available to you. Without this information, we are not able to provide you with all the requested services.
      				</li>
      				<li>Depending on the version of the Platform you are using, you may be able to decide whether you see certain types of interest-based advertising on the Platform by visiting your in-app settings.</li>
      				<li>
      					Your device may have controls that determine what information we collect or how we can use that information. For example, you can control whether we can use your mobile advertising identifier for advertising through
      					settings on your Apple and Android devices.
      				</li>
      				<li>You can opt out of marketing or advertising emails by using the &ldquo;unsubscribe&rdquo; link or mechanism noted in marketing or advertising emails.</li>
      				<li>
      					If you previously chose to share precise location information, you can prevent your device from sharing precise location information (e.g. GPS location information) with the Platform at any time through your
      					device&rsquo;s operating system settings.&nbsp;
      				</li>
      				<li>If you have registered for an account, you may access, review, and update certain personal information that you have provided to us by logging into your account and using available features and functionalities.</li>
      				<li>Some browsers transmit &ldquo;do-not-track&rdquo; signals to websites. Because of differences in how browsers incorporate and activate this feature, we currently do not take action in response to these signals.</li>
      			</ul>
      			<h2><strong>Security and Storage</strong></h2>
      			<p>
      				We use reasonable measures to help protect information from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction. You should understand that no data storage system or transmission of data over
      				the Internet or any other public network can be guaranteed to be 100 percent secure. Please note that information collected by third parties may not have the same security protections as information you submit to us, and we
      				are not responsible for protecting the security of such information.
      			</p>
      			<p>
      				{title} may transmit your data to its servers or data centers outside of the United States for storage and/or processing. Third parties with whom {title} may share your data as described herein may be located outside of the
      				United States.
      			</p>
      			<h2><strong>Other Rights</strong></h2>
      			<h3><strong>Sharing for Direct Marketing Purposes (Shine the Light)</strong></h3>
      			<p>
      				If you are a California resident, once a calendar year, you may be entitled to obtain information about personal information that we shared, if any, with other businesses for their own direct marketing uses. If applicable,
      				this information would include the categories of customer information, as well as the names and addresses of those businesses with which we shared customer information for the immediately prior calendar year.
      			</p>
      		</div>
      	</section>
    </Card.Body>
    </Card>
  </Container>
