import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card, User, Avatar } from '@nextui-org/react'
import { XMasonry, XBlock } from "react-xmasonry"
import { Link, useParams } from "react-router-dom"
import { Chrono } from "react-chrono"
import uniqueRandomArray from 'unique-random-array'

import communities from '../data/communities'
import {orgMap} from '../data/orgs'
import members from '../data/members'

getMember = uniqueRandomArray members


export default Org = ->
  {org} = useParams()
  {admin, domain, name, title, description, bgColor} = orgMap[org]
  bgColor ?= '#f7f7f7'

  logs = [
    title: 'About'
    cardTitle: title
    cardSubtitle: domain
    cardDetailedText: description
  ]

  <>
    <div style={padding: '20px', backgroundColor: bgColor}>
      <Grid.Container gap={2}>
        <Grid xs={4}>
          <Link to='/'>
            <Card variant="flat">
              <Card.Body>
                <Text b>
                  {"< Back"}
                </Text>
              </Card.Body>
            </Card>
          </Link>
        </Grid>
      </Grid.Container>
      <Container align='center'>
        <img
          src="https://image.bbeeee.cloud/#{domain}/#{name}-org-profile.png"
          height='500px'
          width='auto'
          alt={name}
        />
      </Container>
      <Spacer y={3} />
    </div>
    <div style={backgroundColor: '#f7f7f7'}>
      <Container align='center' css={py: 50}>
        <User
          src="https://joinus.community/avatar/#{admin.id}"
          name={admin.name}
          description="Moderator"

        />
        <Spacer y={1} />
        <Button
          as={Link}
          to="/membership/org/#{org}"
          style={width: 200}
          flat
          ghost
          color='gradient'
        >
          JoinUs
        </Button>
        <Spacer y={1} />
        <Avatar.Group>
        {
          [0..9].map ->
            id = getMember()
            <Avatar
              key={"avatar-#{id}"}
              size="lg"
              pointer
              src={"https://joinus.community/avatar/#{id}"}
              bordered
              color="gradient"
              stacked
            />
        }
        </Avatar.Group>
      </Container>
    </div>
    <Container align='center' css={p: 20}>
      <Button flat size='xl' color='primary' as={Link} to="/app/#{name}">Get App</Button>
    </Container>
    <Chrono
      items={logs}
      mode="VERTICAL_ALTERNATING"
      useReadMore
      enableOutline
    />
  </>
