import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card, User, Avatar } from '@nextui-org/react'
import { XMasonry, XBlock } from "react-xmasonry"
import { Link, useParams } from "react-router-dom"

import {orgMap} from '../../data/orgs'


export default Membership = ->
  {org} = useParams()
  {admin, domain, name, title, description, bgColor} = orgMap[org]
  <>
    <Grid.Container gap={2}>
      <Grid xs={4}>
        <Link to='/'>
          <Card variant="flat">
            <Card.Body>
              <Text b>
                {"< Back"}
              </Text>
            </Card.Body>
          </Card>
        </Link>
      </Grid>
    </Grid.Container>
    <Container justify='center' align='center' css={p: 20}>
      <Card variant="flat">
        <Card.Body>
          <Text h1 align='center'>{title} Membership</Text>
          <Container css={p: 20}>
            <Text size="$3xl">Join us and get an early taste of our app before its official launch. Members can also join premium and above plans for free or at a discount in the official version.</Text>
            <Text size="$3xl">Whether you want to sponsor or be sponsored, please contact us: <Text b>sponsor@{domain}</Text></Text>
          </Container>
        </Card.Body>
      </Card>
    </Container>
  </>
