import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card } from '@nextui-org/react'
import { XMasonry, XBlock } from "react-xmasonry"
import { Link } from "react-router-dom"

export CommunityCard = ({name, domain, title, description, logo}) ->
  <Card as={Link} to="/community/#{name}" isPressable isHoverable css={{ p: "$6", mw: "400px", h: "150px", jc: 'center' }}>
    <Card.Header>
      <img
        alt={name}
        src={logo}
        width="90px"
        height="auto"
      />
      <Grid.Container css={{ pl: "$6" }}>
        <Grid xs={12}>
          <Text h4 css={{ lineHeight: "$xs" }}>
          {title}
          </Text>
        </Grid>
        <Grid xs={12}>
          <Text css={{ color: "$accents8" }}>{domain}</Text>
        </Grid>
      </Grid.Container>
    </Card.Header>
  </Card>


export OrgCard = ({mode, stage, community, title, name, domain, description, bgColor, objectFit}) ->
  bgColor ?= '#000000'
  <Card as={Link} to="/org/#{name}" isPressable isHoverable css={{ w: "400px", h: "800px" }} style={backgroundColor: bgColor}>
    <Card.Header css={{ position: "absolute", zIndex: 1, top: 0, bgColor: 'rgba(0, 0, 0, 0.9)' }}>
      <Col>
        <Text size={12} weight="bold" transform="uppercase" color="#9E9E9E">
        {domain}
        </Text>
        <Text h3 color="white">
        {title}
        </Text>
      </Col>
    </Card.Header>
    <Card.Body css={{ p: 0, dflex: 'center' }}>
      <Card.Image
        objectFit={objectFit}
        src="https://image.bbeeee.cloud/#{domain}/#{name}-splash.png"
        width="100%"
        height="100%"
        alt={description}
      />
    </Card.Body>
    <Card.Footer
      isBlurred
      css={{
        position: "absolute",
        bgColor: "rgba(0, 0, 0, 0.9)",
        bottom: 0,
        zIndex: 1,
      }}
    >
      <Row>
        <Col>
          <Row>
            <Col span={3}>
              <Card.Image
                src="https://image.bbeeee.cloud/#{domain}/#{name}-icon.png"
                height={40}
                width={40}
                alt={name}
              />
            </Col>
            <Col>
              <Text color="#d1d1d1" size={12}>
              </Text>
              <Text color="#d1d1d1" size={12}>
              </Text>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify="flex-end">
            <Button
              as={Link}
              to="/app/#{name}"
              flat
              auto
              rounded
            >
              <Text
                css={{ color: "inherit" }}
                size={12}
                weight="bold"
                transform="uppercase"
              >
                Get App
              </Text>
            </Button>
          </Row>
        </Col>
      </Row>
    </Card.Footer>
  </Card>
