export default apps = {
  aassss: {
    apk: {
      title: 'AASSSS'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/aassss/1.0.0/preview/application-4bc50b90-b679-462f-97ed-9d8fff6ade6a.apk'
    }
  }
  alacool: {
    apk: {
      title: 'ALA.COOL'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/alacool/1.0.0/preview/application-ef85a474-a527-4940-92a3-9bdb3ab22c96.apk'
    }
  }
  alatoday: {
    apk: {
      title: 'ALA.TODAY'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/alatoday/1.0.0/preview/application-231f7ac0-65b1-4289-8431-31aa414a84fa.apk'
    }
  }
  anavaya: {
    apk: {
      title: 'ANAVAYA'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/anavaya/1.0.0/preview/application-6e97aaea-c941-42ec-9226-5cf6a7453518.apk'
    }
  }
  barebrain: {
    apk: {
      title: 'BareBrain'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/barebrain/1.0.0/preview/application-670e70a0-4d20-4333-9820-6ab7d0b1d1ee.apk'
    }
  }
  bboott: {
    apk: {
      title: 'BBOOTT'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/bboott/1.0.0/preview/application-b15a84b3-39ae-4d9e-a897-54405b902f4d.apk'
    }
  }
  bickini: {
    apk: {
      title: 'BICKINI'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/bickini/1.0.0/preview/application-dbe63baa-d021-443c-b32c-a8b5d6375a32.apk'
    }
  }
  bizarrella: {
    apk: {
      title: 'BIZARRELLA'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/bizarrella/1.0.0/preview/application-5210ca29-4123-40d3-88b4-c7b45ec4ac96.apk'
    }
  }
  boocool: {
    apk: {
      title: 'Boo.Cool'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/boocool/1.0.0/preview/application-0dddfa0a-a220-4b76-8672-6a9a670899e5.apk'
    }
  }
  borringnews: {
    apk: {
      title: 'BorringNews'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/borringnews/1.0.0/preview/application-6faac53e-bb6f-452a-8ee2-360356eb8ce8.apk'
    }
  }
  bouncekidzz: {
    apk: {
      title: 'BounceKidzz'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/bouncekidzz/1.0.0/preview/application-d857442a-5c84-4050-ad00-e6b8a3ecbc8b.apk'
    }
  }
  burritozilla: {
    apk: {
      title: 'Burritozil.la'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/burritozilla/1.0.0/preview/application-b8e06fff-d946-4bb9-89f6-d890992d5a1a.apk'
    }
  }
  cabanala: {
    apk: {
      title: 'CABANALA'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/cabanala/1.0.0/preview/application-7a5d538f-9c96-4689-b9b7-2f34150fefd4.apk'
    }
  }
  chainchoo: {
    apk: {
      title: 'ChainChoo'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/chainchoo/1.0.0/preview/application-43b8e736-53b5-4504-a4c8-ca370612def6.apk'
    }
  }
  cocupa: {
    apk: {
      title: 'CoCuPa'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/cocupa/1.0.0/preview/application-1db73f88-5d88-4383-8103-b933162d9278.apk'
    }
  }
  colorso: {
    apk: {
      title: 'CoCuPa'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/colorso/1.0.0/preview/application-2d419a6c-08e7-4609-8efc-c4bbdbac4b6d.apk'
    }
  }
  dapala: {
    apk: {
      title: 'dapa.la'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/dapala/1.0.0/preview/application-ff096c42-e780-412d-8b59-79e1b502bdb8.apk'
    }
  }
  ddooggdog: {
    apk: {
      title: 'DDOOGG.DOG'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/ddooggdog/1.0.0/preview/application-6d7f5353-ae12-40c1-a9a9-bc8e06c4e1f1.apk'
    }
  }
  dripdrip: {
    apk: {
      title: 'DripD.rip'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/dripdrip/1.0.0/preview/application-b6765d34-8c35-4eea-a6c8-808de0e6c9d1.apk'
    }
  }
  fiixxx: {
    apk: {
      title: 'FIIXXX'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/fiixxx/1.0.0/preview/application-4416b2d9-8dad-4d5c-9b73-77573645fca5.apk'
    }
  }
  foofxx: {
    apk: {
      title: 'FooFxx'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/foofxx/1.0.0/preview/application-a6526388-f017-4f01-93f5-70cfdc1f80c6.apk'
    }
  }
  fooxxx: {
    apk: {
      title: 'FOOXXX'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/fooxxx/1.0.0/preview/application-d79d044e-b4b1-4415-bb73-e61181459d50.apk'
    }
  }
  g63gg: {
    apk: {
      title: 'g63.gg'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/g63gg/1.0.0/preview/application-981f6d18-03cb-4005-adf2-0daea6f43754.apk'
    }
  }
  geekfail: {
    apk: {
      title: 'Geek.Fail'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/geekfail/1.0.0/preview/application-ff6b06c5-675e-40f3-8b8a-0d84c7456f0e.apk'
    }
  }
  ggiinnin: {
    apk: {
      title: 'GGIINN.IN'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/ggiinnin/1.0.0/preview/application-d0c75742-1b67-4184-b4ff-bf0c367c0589.apk'
    }
  }
  hihihoho: {
    apk: {
      title: 'HIHIHOHO'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/hihihoho/1.0.0/preview/application-03b754b4-305c-44a6-b1ab-403b574674e8.apk'
    }
  }
  iinnin: {
    apk: {
      title: 'IINN.IN'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/iinnin/1.0.0/preview/application-6cd77927-3f1d-42e9-b7fe-9493db1c42be.apk'
    }
  }
  infomationtechnology: {
    apk: {
      title: 'Infomation.Technology'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/infomationtechnology/1.0.0/preview/application-d37e6ccd-c6b0-40bc-aff0-7ce066e321eb.apk'
    }
  }
  jagerdumpling: {
    apk: {
      title: 'Jagerdumpling'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/jagerdumpling/1.0.0/preview/application-1ffc18cd-c00c-4b74-8250-0ec5f8226e30.apk'
    }
  }
  jimjim: {
    apk: {
      title: 'JIMJ.IM'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/jimjim/1.0.0/preview/application-3ee31dcc-feeb-45b9-a101-dba2d0739541.apk'
    }
  }
  jumpjumpbangbang: {
    apk: {
      title: 'JumpJumpBangBang'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/jumpjumpbangbang/1.0.0/preview/application-ba8ea96e-2f8a-4ebb-8bf2-529bdb6bd554.apk'
    }
  }
  listenrcloud: {
    apk: {
      title: 'Listenr.Cloud'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/listenrcloud/1.0.0/preview/application-e47b19a6-c970-4ef3-968e-a705c5ba3592.apk'
    }
  }
  manzanilla: {
    apk: {
      title: 'Manzanil.la'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/manzanilla/1.0.0/preview/application-c8b4028e-e9e6-4b31-82cb-237b196745ce.apk'
    }
  }
  mipier: {
    apk: {
      title: 'Mipier'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/mipier/1.0.0/preview/application-5e6565b8-4fec-43b9-832d-1b2c56b6702f.apk'
    }
  }
  mmiixxin: {
    apk: {
      title: 'MMIIXX.IN'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/mmiixxin/1.0.0/preview/application-730dc264-d9b2-4144-b923-38ce8db21080.apk'
    }
  }
  mubla: {
    apk: {
      title: 'MUB.LA'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/mubla/1.0.0/preview/application-d6ca69cc-beb7-4687-846b-c2a94b7f3319.apk'
    }
  }
  navava: {
    apk: {
      title: 'NAVAVA'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/navava/1.0.0/preview/application-71c9896b-2269-46f9-baf1-e8e52c52ef82.apk'
    }
  }
  neighborfoo: {
    apk: {
      title: 'NeighborFoo'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/neighborfoo/1.0.0/preview/application-b727e300-e052-4cb2-bf51-151af14a182d.apk'
    }
  }
  nightube: {
    store: {
      google: {
        url: 'https://play.google.com/store/apps/details?id=be.nightu.app'
      }
    }
    apk: {
      title: 'NIGHTU.BE'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/nightube/1.0.0/preview/application-e1978ac4-3ba4-48be-b140-6690738c50c2.apk'
    }
  }
  oohso: {
    apk: {
      title: 'Ooh.so'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/oohso/1.0.0/preview/application-99037600-d0f1-402a-8acb-1ba56671d728.apk'
    }
  }
  peopleshouldknow: {
    apk: {
      title: 'People Should Know'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/peopleshouldknow/1.0.0/preview/application-b49c57d7-9940-4635-a633-423a2563e035.apk'
    }
  }
  ppllpl: {
    apk: {
      title: 'PPLL.PL'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/ppllpl/1.0.0/preview/application-be24a38f-258c-47a9-939d-920c2bc346e5.apk'
    }
  }
  raprip: {
    apk: {
      title: ''
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/raprip/1.0.0/preview/application-7d8e4644-b887-4717-bc6f-63e8044485b2.apk'
    }
  }
  redribbonrider: {
    apk: {
      title: ''
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/redribbonrider/1.0.0/preview/application-e81aa21c-6fe2-48ec-8190-1dfdabbfc5ff.apk'
    }
  }
  streetfoo: {
    apk: {
      title: 'StreetFoo'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/streetfoo/1.0.0/preview/application-9238c981-c44a-4cd0-8328-e75a3c1c4da4.apk'
    }
  }
  vervet: {
    apk: {
      title: 'VER.VET'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/vervet/1.0.0/preview/application-76a93e20-6305-406c-9563-9f42e8024afc.apk'
    }
  }
  woocool: {
    apk: {
      title: 'Woo.Cool'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/woocool/1.0.0/preview/application-090a5927-967e-4876-b8f0-5e1ae6ad7938.apk'
    }
  }
  worldmoviemap: {
    apk: {
      title: 'World Movie Map'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/worldmoviemap/1.0.0/preview/application-234d3702-dfc9-4f22-a67f-15a5240091bf.apk'
    }
  }
  wwoomen: {
    apk: {
      title: 'WWOO.MEN'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/wwoomen/1.0.0/preview/application-bc23e42f-1d49-4994-8287-9779386b97ef.apk'
    }
  }
  zzoooo: {
    apk: {
      title: 'ZZOOOO'
      version: '1.0.0 Preview'
      url: 'https://joinus.community/download/zzoooo/1.0.0/preview/application-ae8fd9ec-eb79-4993-9c19-4bcb635a4eba.apk'
    }
  }
}
