import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card, User, Avatar } from '@nextui-org/react'
import { XMasonry, XBlock } from "react-xmasonry"
import { Link, useParams } from "react-router-dom"

import communities from '../../data/communities'


export default Membership = ->
  {community} = useParams()
  {name, domain, title, description, logo} = communities[community]
  <>
    <Grid.Container gap={2}>
      <Grid xs={4}>
        <Link to='/'>
          <Card variant="flat">
            <Card.Body>
              <Text b>
                {"< Back"}
              </Text>
            </Card.Body>
          </Card>
        </Link>
      </Grid>
    </Grid.Container>
    <Container justify='center' align='center' css={p: 20}>
      <Card variant="flat">
        <Card.Body>
          <Text h1 align='center'>{title} Membership</Text>
          <Container css={p: 20}>
            <Text size="$3xl">If you feel like you belong in our community and would like to share resources, ideas, content and users with other organizations in the community. You can create your own organization and build your own applications based on BBEEEE.CLOUD and VALALA.MOBI.</Text>
            <Text size="$3xl">Whether you want to sponsor or be sponsored, please contact us: <Text b>sponsor@{domain}</Text></Text>
          </Container>
        </Card.Body>
      </Card>
    </Container>
  </>
