import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card, User, Avatar } from '@nextui-org/react'

export default APK = ({width, title, version, url}) ->
  <a href={url}>
    <Text>{version}</Text>
    <Text h1>{title}</Text>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width * 0.5} height={width * 0.5} x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000">
      <g>
        <g>
          <path d="M252.2,512.4c-4.9-16.3-9.8-36.8-13.9-53.1h-0.8c-4.1,16.3-8.2,37.2-12.7,53.1l-16.3,58.4h60.8L252.2,512.4z" />
          <path d="M490.7,457.7c-12.7,0-21.2,1.2-25.7,2.4v81.3c5.3,1.2,11.9,1.6,20.8,1.6c33.1,0,53.5-16.7,53.5-44.9C539.3,472.8,521.7,457.7,490.7,457.7z" />
          <path
              d="M879,360.5h-23.5V247c0-0.7-0.1-1.4-0.2-2.1c0-4.5-1.5-8.9-4.6-12.4L661.8,16.6c-0.1-0.1-0.1-0.1-0.2-0.1c-1.1-1.3-2.4-2.3-3.8-3.2c-0.4-0.3-0.8-0.5-1.3-0.8c-1.2-0.7-2.5-1.2-3.8-1.6c-0.4-0.1-0.7-0.2-1-0.3c-1.4-0.3-2.9-0.5-4.4-0.5H182.9c-21.2,0-38.4,17.2-38.4,38.4v312.1H121c-30.3,0-54.9,24.6-54.9,54.9v285.6c0,30.3,24.6,54.9,54.9,54.9h23.5v195.5c0,21.2,17.2,38.4,38.4,38.4h634.1c21.2,0,38.4-17.2,38.4-38.4V756H879c30.3,0,54.9-24.6,54.9-54.9V415.5C933.9,385.1,909.3,360.5,879,360.5z M182.9,48.4h445.1v196.6c0,10.6,8.6,19.2,19.2,19.2h169.8v96.3H182.9V48.4L182.9,48.4z M601,496.5c0,26.9-9,49.8-25.3,65.3c-21.3,20-52.7,29-89.4,29c-8.2,0-15.5-0.4-21.2-1.2V688h-61.7V416.5c19.2-3.3,46.2-5.7,84.1-5.7c38.4,0,65.7,7.3,84.1,22C589.1,446.7,601,469.6,601,496.5z M179.9,688h-64.5l84.1-275.2h81.7L366.5,688h-67l-21.2-70.6h-78.8L179.9,688z M817.1,941.2H182.9V756h634.1V941.2z M793.3,688l-67.4-118.8l-23.7,29V688h-61.7V412.8h61.7v121.7h1.2c6.1-10.6,12.7-20.4,18.8-30.2l62.5-91.5h76.3L770,530l96,158L793.3,688L793.3,688L793.3,688z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </g>
    </svg>
  </a>
