import React from 'react'
import {NextUIProvider} from '@nextui-org/react'
import ReactDOM from "react-dom/client"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Home from './pages/Home'
import Community from './pages/Community'
import Org from './pages/Org'
import App from './pages/App'
import Privacy from './pages/Privacy'
import Membership from './pages/Membership'
import MembershipCommunity from './pages/Membership/Community'
import MembershipOrg from './pages/Membership/Org'


router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  }
  {
    path: "/community/:community"
    element: <Community />
  }
  {
    path: "/org/:org"
    element: <Org />
  }
  {
    path: "/app/:app"
    element: <App />
  }
  {
    path: "/privacy/:org"
    element: <Privacy />
  }
  {
    path: "/membership"
    element: <Membership />
  }
  {
    path: "/membership/community/:community"
    element: <MembershipCommunity />
  }
  {
    path: "/membership/org/:org"
    element: <MembershipOrg />
  }
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <NextUIProvider>
      <RouterProvider router={router} />
    </NextUIProvider>
  </React.StrictMode>
  )
