import React from 'react'
import { Container, Grid, Col, Row, Spacer, Image, Button, Text, Card, User, Avatar } from '@nextui-org/react'
import { Link, useParams } from "react-router-dom"
import { XMasonry, XBlock } from "react-xmasonry"
import useWindowWidth from 'react-hook-use-window-width'

import Google from '../components/Download/Google'
import Apple from '../components/Download/Apple'
import APK from '../components/Download/APK'

import apps from '../data/apps'

aspectRatio = 1080 / 320

export default App = ->
  {app} = useParams()
  winWidth = useWindowWidth()
  width = winWidth * 0.2
  height = width / aspectRatio
  {store, apk} = apps[app] or {}


  <>
    <Grid.Container gap={2}>
      <Grid xs={4}>
        <Link to='/'>
          <Card variant="flat">
            <Card.Body>
              <Text b>
                {"< Back"}
              </Text>
            </Card.Body>
          </Card>
        </Link>
      </Grid>
    </Grid.Container>
    <Container justify='center' align='center' css={p: 20}>
      <Card variant="flat">
        <Card.Body>
        {
          if store? or apk?
            <Container justify='center' align='center' css={p: 100}>
            {
              if apk?
                <Container justify='center' align='center' css={mb: 100}>
                  <APK width={width} {...apk} />
                </Container>
            }
              <Google width={width} height={height} {...(store?.google or {})} />
              <Spacer y={3} />
              <Apple width={width} height={height} />
            </Container>
          else
            <Text align='center'>App not released yet.</Text>
        }
        </Card.Body>
      </Card>
    </Container>
  </>
